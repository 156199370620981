<div class="header-container">
  <div class="logo-container">
      <a (click)="toggleSidebar()" href="/" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline"></nb-icon>
      </a>
      <a href="/">
        <img class="weewee-logo" src="assets/logo/Logo_Horizontal Purple Tagline.svg" alt="logo">
      </a>
      <!-- <a class="logo" href="/">WW<span class="mx-2">v0.035</span></a> -->
      <div class="themes-change">
        <nb-select placeholder="Theme" (selectedChange)="changeTheme($event)">
          <nb-option *ngFor="let theme of themes" [value]="theme.value">{{theme.name}}</nb-option>
        </nb-select>
      </div>
  </div>
</div>

<div class="header-container">

  <nb-search type="modal-drop"></nb-search>
  <nb-actions size="small">
    <nb-action class="control-item">{{fullName}}</nb-action>
    <nb-action class="control-item">{{role}}</nb-action>
    <nb-action class="control-item" (click)="showProfile()" class="person" ><nb-icon icon="person-outline"></nb-icon></nb-action>
    <nb-action class="control-item" class="logout" (click)="searchPage()" ><nb-icon icon="search-outline"></nb-icon></nb-action>
    <nb-action class="control-item" class="logout" (click)="showInfo()" ><nb-icon icon="info-outline"></nb-icon></nb-action>
    <nb-action class="control-item" (click)="logout()" class="logout"><nb-icon icon="log-out-outline"></nb-icon></nb-action>
  </nb-actions>
</div>
