import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


// ****** Added
import { inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Auth, User, user, getAuth, signOut } from '@angular/fire/auth';
import { filter/*, map*/ } from 'rxjs/operators';
//
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

import { Auth99Service } from '../../../auth99.service';
import { NbSearchService } from '@nebular/theme';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  // ****** Added
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  userSubscription: Subscription;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  name: string = "*";
  fullName: string = "*";
  role: string = "*";

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ tag: 'profile', title: 'Profile' }, { tag: 'logout', title: 'Log out' }];


  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private toastrService: NbToastrService,
    private aAuth99Service: Auth99Service,
    private searchService: NbSearchService,
    private router: Router) {
    // ****** Added
    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      /*this.name = aUser?.email || "**";
      console.log("Haha", this.name, aUser);
      if(!aUser) this.router.navigateByUrl('/pages/auth-login');*/
    })

    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        let searchTerm = data.term || "";
        searchTerm = searchTerm.toUpperCase();
        if (searchTerm.startsWith("W")) {
          this.router.navigate(["pages", "tarek-request2-edit", searchTerm]);
        } else if (searchTerm.startsWith("C")) {
          this.router.navigate(["pages", "tarek-request3-edit", searchTerm]);
        } else if (searchTerm.startsWith("P")) {
          this.router.navigate(["pages", "tarek-request3-edit", searchTerm]);
        }
        /*else if (searchTerm.startsWith("+213") && !searchTerm.endsWith("*")) {
          this.router.navigate(["pages", "tarek-trader-details", searchTerm.replace('*', '')]);
        }
        else if (searchTerm.startsWith("+213") && searchTerm.endsWith("*")) {
          this.router.navigate(["pages", "tarek-driver-details", searchTerm.replace('*', '')]);
        }*/
        else {
          //this.toastrService.show('Vous pouvez rechercher : Utilisateurs (+213*) ou Packages (W*)', "Recherche invalide !", { status: 'warning', position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, destroyByClick: true });
          ///pages/tarek-search/*
          this.router.navigate(["pages", "tarek-search", searchTerm]);
        }
      })
  }

  menuServiceSubscription: Subscription;
  currentAuthUserSubscription: Subscription;
  ngOnInit() {

    //
    this.currentAuthUserSubscription = this.aAuth99Service.getCurrentUserAuth$().subscribe(aAuthUser => {
      this.name = aAuthUser?.email || "**--";
      this.fullName = aAuthUser?.fullName || "**--";
      this.role = aAuthUser?.role || "**--";
    })

    this.saveCurrentTheme()


    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

      this.themeService.onThemeChange().subscribe(theme => {
        this.currentTheme = theme.name;
        localStorage.setItem('theme', theme.name);
      });

    this.menuServiceSubscription = this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag == 'user-context-menu')
      )
      .subscribe(item => {
        const itemVal: any = item.item as any;
        if (itemVal.tag == "profile") {
          console.log("Show profile");
          this.router.navigate(["pages", "tarek-profile"]);
        }
        else if (itemVal.tag == "logout") {
          this.aAuth99Service.logout();
        }
      });
  }

  ngOnDestroy() {
    this.currentAuthUserSubscription.unsubscribe();
    this.menuServiceSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveCurrentTheme() {
    const storeTheme = localStorage.getItem('theme');
    if (storeTheme) {
      this.currentTheme = storeTheme;
      this.themeService.changeTheme(storeTheme);
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    this.currentTheme = themeName
    console.log('theme', this.currentTheme, themeName)
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  showProfile() {
    this.router.navigate(["pages", "tarek-profile"]);
  }

  showInfo() {
    this.router.navigate(["pages", "info"]);
  }

  searchPage() {
    this.router.navigate(["pages", "search"]);
  }

  logout() {
    this.aAuth99Service.logout();
  }
}
