export class UserAuth {
    public uid: string = "";
    public displayName: string = "";
    public phoneNumber: string = "";
    public email: string = "";
    public active: string = "";
    public role: string = "";
    public wilaya: string = "";
    public fullName: string = "";
    public password?: string = "";
    public refWilaya?: string = "";

    public constructor(init?: Partial<UserAuth>) {
        Object.assign(this, init);
    }

    //
    public static roleEnums = [
        { value: "", text: "/" },
        { value: "operation", text: "Opération" }, // (OPERATION)
        { value: "wilaya-desk", text: "Bereau Wilaya" }, // (WILAYA)
        { value: "user", text: "Expéditeur" }, // (E-COMMERÇANT)
        { value: "driver", text: "Transporteur" }, // (LIVREUR)
        { value: "supervisor", text: "Supervisor" },
        { value: "coordinator", text: "Coordinator" },
        { value: "admin", text: "Admin" },
        { value: "admin-it", text: "Admin IT" },
        { value: "customer-service", text: "Customer Service"}
    ];
    public get _role() {
        return UserAuth.roleEnums.find(item => item.value == this.role)?.text || this.role;
    };
    public static activeEnums = [
        { value: "", text: "/" },
        { value: "no", text: "No" },
        { value: "yes", text: "Yes" },
    ];
    public get _active() {
        return UserAuth.activeEnums.find(item => item.value == this.active)?.text || this.active;
    };

    public get _allWilayas() {
        let wilaya = this.refWilaya || this.wilaya;
        if (wilaya == "*")
            return [
                "Adrar", "Chlef", "Laghouat", "Oum El Bouaghi", "Batna", "Béjaïa", "Biskra", "Bechar", "Blida", "Bouira",
                "Tamanrasset", "Tbessa", "Tlemcen", "Tiaret", "Tizi Ouzou", "Alger", "Djelfa", "Jijel", "Setif", "Saefda",
                "Skikda", "Sidi Bel Abbes", "Annaba", "Guelma", "Constantine", "Medea", "Mostaganem", "M'Sila", "Mascara",
                "Ouargla", "Oran", "El Bayadh", "Illizi", "Bordj Bou Arreridj", "Boumerdes", "El Tarf", "Tindouf",
                "Tissemsilt", "El Oued", "Khenchela", "Souk Ahras", "Tipaza", "Mila", "Ain Defla", "Naama",
                "Ain Temouchent", "Ghardaefa", "Relizane", "El M'ghair", "El Menia", "Ouled Djellal",
                "Bordj Baji Mokhtar", "Béni Abbès", "Timimoun", "Touggourt", "Djanet", "In Salah", "In Guezzam"
            ];
        else if (wilaya == "Alger")
            return [
                "Alger",
                "Blida",
                "Boumerdes",
                "Tipaza"
            ];
        else if (wilaya == "Jijel")
            return [
                "Jijel",
                "Mila",
                "Constantine",
                "Béjaïa",
                "Skikda"
            ];
        else
            return [
                wilaya
            ];
    };

    public get _AllWilayasLabel() {
        return this.wilaya + ' : ' + this._allWilayas.join(', ');
    };

    public get _wilayaCode() {
      switch (this.wilaya) {
        case "Adrar": return "W01-1";
        case "Chlef": return "W02-1";
        case "Laghouat": return "W03-1";
        case "Oum El Bouaghi": return "W04-1";
        case "Batna": return "W05-1";
        case "Béjaïa": return "W06-1";
        case "Biskra": return "W07-1";
        case "Bechar": return "W08-1";
        case "Blida": return "W09-1";
        case "Bouira": return "W10-1";
        case "Tamanrasset": return "W11-1";
        case "Tbessa": return "W12-1";
        case "Tlemcen": return "W13-1";
        case "Tiaret": return "W14-1";
        case "Tizi Ouzou": return "W15-1";
        case "Alger": return "W16-1";
        case "Djelfa": return "W17-1";
        case "Jijel": return "W18-1";
        case "Setif": return "W19-1";
        case "Saefda": return "W20-1";
        case "Skikda": return "W21-1";
        case "Sidi Bel Abbes": return "W22-1";
        case "Annaba": return "W23-1";
        case "Guelma": return "W24-1";
        case "Constantine": return "W25-1";
        case "Medea": return "W26-1";
        case "Mostaganem": return "W27-1";
        case "M'Sila": return "W28-1";
        case "Mascara": return "W29-1";
        case "Ouargla": return "W30-1";
        case "Oran": return "W31-1";
        case "El Bayadh": return "W32-1";
        case "Illizi": return "W33-1";
        case "Bordj Bou Arreridj": return "W34-1";
        case "Boumerdes": return "W35-1";
        case "El Tarf": return "W36-1";
        case "Tindouf": return "W37-1";
        case "Tissemsilt": return "W38-1";
        case "El Oued": return "W39-1";
        case "Khenchela": return "W40-1";
        case "Souk Ahras": return "W41-1";
        case "Tipaza": return "W42-1";
        case "Mila": return "W43-1";
        case "Ain Defla": return "W44-1";
        case "Naama": return "W45-1";
        case "Ain Temouchent": return "W46-1";
        case "Ghardaefa": return "W47-1";
        case "Relizane": return "W48-1";
        case "El M'ghair": return "W49-1";
        case "El Menia": return "W50-1";
        case "Ouled Djellal": return "W51-1";
        case "Bordj Baji Mokhtar": return "W52-1";
        case "Béni Abbès": return "W53-1";
        case "Timimoun": return "W54-1";
        case "Touggourt": return "W55-1";
        case "Djanet": return "W56-1";
        case "In Salah": return "W57-1";
        case "In Guezzam": return "W58-1";
        default: return "W00-1";
      }
    }
};
